<template>
  <span class="alt-language-select">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <select
        v-model="currentLanguage"
        class="form-control m-input m-input--solid"
      >
        <option
          selected="selected"
          disabled="disabled"
        >{{ $t('pleaseSelectOne') }}</option>
        <template v-for="(locale, index) in languages">
          <option
            :key="`option${index}`"
            :value="locale.countryCode"
          >
            <span>{{ locale.language }}</span>
          </option>
        </template>
      </select>
    </template>
  </span>
</template>

<script>
export default {
  name: "LanguageSelect",
  props: {
    language: {
      type: String,
      required: false,
      default: ''
    },
    usedLanguages: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      loading: true,
      languages: null,
      currentLanguage: null
    }
  },
  watch: {
    currentLanguage () {
      this.updateLanguage();
    }
  },
  created () {
    this.getLanguages();
  },
  methods: {
    updateLanguage () {
      this.$emit('selectedLanguage', this.currentLanguage);
    },
    getLanguages () {
      this.loading = true;
      this.axios.get('/SmsManagement/GetDefaultLanguages')
        .then((response) => {
          this.languages = response.data;
          this.languages = this.languages.filter((locale) => !this.usedLanguages.includes(locale.countryCode));
          if (!(this.languages && this.languages.length > 0)) {
            this.loading = false;
            return;
          }
          if (this.language) {
            this.currentLanguage = this.language;
          } else {
            this.currentLanguage = this.languages[0].countryCode;
          }
          this.updateLanguage();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>
select.form-control {
  border: 1px solid gray;
}
</style>
